export function DateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#8990FF"
        fillRule="evenodd"
        d="M14.438 12.75V9.375h1.124v3.375c0 1.14-.923 2.063-2.062 2.063H5.25a2.062 2.062 0 01-2.063-2.063V10.5h1.126v2.25c0 .518.42.938.937.938h8.25c.518 0 .938-.42.938-.938z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#8990FF"
        fillRule="evenodd"
        d="M7.688 2.625a.563.563 0 00-1.125 0v1.313h-1.6c-.497 0-.951.28-1.174.725l-1.5 3c-.436.873.198 1.9 1.174 1.9h9.496c.565 0 1.066-.362 1.245-.898l1-3a1.313 1.313 0 00-1.245-1.728h-1.396V2.625a.562.562 0 10-1.126 0v1.313h-3.75V2.625zM4.795 5.166a.188.188 0 01.168-.104h8.996c.128 0 .218.126.178.247l-1 3a.188.188 0 01-.178.129H3.463a.187.187 0 01-.168-.272l1.5-3z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#8990FF"
        d="M6 11.25c0-.207.168-.375.375-.375h.375c.207 0 .375.168.375.375v.375A.375.375 0 016.75 12h-.375A.375.375 0 016 11.625v-.375zM10.875 11.25c0-.207.168-.375.375-.375h.375c.207 0 .375.168.375.375v.375a.375.375 0 01-.375.375h-.375a.375.375 0 01-.375-.375v-.375zM8.438 11.25c0-.207.167-.375.374-.375h.376c.207 0 .374.168.374.375v.375a.375.375 0 01-.374.375h-.376a.375.375 0 01-.374-.375v-.375z"
      ></path>
    </svg>
  );
}
