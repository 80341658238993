export function MapIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <g
        fill="#8990FF"
        fillRule="evenodd"
        clipPath="url(#clip0_3265_17461)"
        clipRule="evenodd"
      >
        <path d="M9.81 14.036l2.58-2.68a4.706 4.706 0 10-6.78 0l2.58 2.68c.442.46 1.178.46 1.62 0zM5.18 3.686a5.831 5.831 0 00-.38 8.45l2.58 2.68a2.25 2.25 0 003.24 0l2.58-2.68a5.831 5.831 0 00-8.02-8.45z"></path>
        <path d="M9 9.375a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM9 10.5a2.625 2.625 0 100-5.25 2.625 2.625 0 000 5.25z"></path>
      </g>
      <defs>
        <clipPath id="clip0_3265_17461">
          <rect width="18" height="18" fill="#fff" rx="4"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}
